html, body,

#root {
    padding: 0;
    margin: 0;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
}

html {
    background: #fafafa;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadeIn {
    animation: fadeIn;
    animation-duration: 300ms;
    animation-fill-mode: both;
    animation-timing-function: ease-in;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fadeOut {
    animation: fadeOut !important;
    animation-duration: 250ms !important;
    ;
    animation-fill-mode: both !important;
    ;
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(40px)
    }

    100% {
        opacity: 1;
        transform: translateY(0px)
    }
}

@keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0px)
    }

    100% {
        opacity: 0;
        transform: translateY(40px)
    }
}

@keyframes fadeRight {
    0% {
        opacity: 0;
        transform: translateX(300px)
    }

    100% {
        opacity: 1;
        transform: translateX(0px)
    }
}

@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0px)
    }

    100% {
        opacity: 0;
        transform: translateX(300px)
    }
}

.dialog-title-between>* {
    display: flex;
    justify-content: space-between;
}

.visible-only-on-pdf {
    display: none;
}

.transparent-select-menu > .MuiPaper-root {
    background: transparent;
    box-shadow: none;
}
.FBListItem div.MuiListItemText-root > span {
    color: rgba(0,0,0,0.38);
}

.FBListItem > div > svg {
    fill: rgba(0,0,0,0.38);
}

.FBListItem .Mui-selected > div > svg {
    fill: #3279CD;
}

.FBListItem .Mui-selected {
    background: linear-gradient(to right, transparent 98%, #3279CD 98%, #3279CD 100%) no-repeat right;
    background-size: 100% 60%;
}

.FBListItem .Mui-selected .MuiListItemText-root span {
    color: rgba(0,0,0,0.87);
}

.text-ellipsis {
    text-overflow: ellipsis;
}

.szh-menu.szh-menu--state-open {
    background-color: white;
    list-style: none;
    padding-left: 0;
}

.szh-menu.szh-menu--state-open > * > span {
    width: 100%;
    display: block;
}

.szh-menu.szh-menu--state-open > * {
    z-index: 999999;
    width: 100%;
    padding: 5px 10px;
    white-space: nowrap;
    font-weight: 400;
    font-family: Poppins,Ubuntu,Roboto;
    font-size: 1rem;
    line-height: 1.5;
    padding-top: 6px;
}

.szh-menu.szh-menu--state-open > li:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04) !important;
}


